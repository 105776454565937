/* You can add global styles to this file, and also import other style files */
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
@import '@ali-hm/angular-tree-component/css/angular-tree-component.css';
:root{
  --bg-offwhite: #f1f5f9;
}

* {
    box-sizing: border-box;
}

html,
body {
    height: 100%;
    width: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif !important;
}

textarea{
  white-space: pre-wrap;
}
.bg-warning-alpha {
  background-color: #ffc10730;
}
.container-lg {
    max-width: 1440px !important ;
    margin: 0 auto;
}

.container-xl {
    max-width: 1920px !important ;
    margin: 0 auto;
}

.container-4k {
  max-width: 3840px !important ;
  margin: 0 auto;
}

.gap-3{
  gap: 1rem;
}

.text-elipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

.mat-mdc-button-base:not(.allow-lowercase){
  text-transform: uppercase;
}

.mat-mdc-snack-bar-label{
  white-space: pre-line;
}

.msg-snack-error  .mdc-snackbar__surface{
    background-color: #ff5f54 !important;
}

.msg-snack-warning  .mdc-snackbar__surface{
    background-color: #e7b40c !important;
}
.msg-snack-success  .mdc-snackbar__surface{
    background-color: green !important;
}
.msg-snack-error  .mdc-snackbar__surface .mat-mdc-button,
.msg-snack-warning  .mdc-snackbar__surface .mat-mdc-button,
.msg-snack-success  .mdc-snackbar__surface .mat-mdc-button {
    background-color: #00000022 !important;
    color: #fff !important;
}

.example-tooltip-conf {
    font-size: 13px;
    text-align: justify;
}

::ng-deep .mat-vertical-stepper-content {
    visibility: visible !important;
    height: 100% !important;
}

.ng-input {
    padding: 8px !important;
}

.error_ngSelect {
    font-size: 11px;
    margin-top: -16px;
    margin-left: 11px;
}

.error_mat_radio {
    font-size: 11px;
    margin-top: -8px;
    margin-left: 11px;
}

table {
    width: 100%;
}

.mat-mdc-slide-toggle label,
.mat-mdc-checkbox label{
  margin: 0;
  padding-left: .5rem;
}

.mat-mdc-dialog-content {
    max-height: unset !important;
}

.mat-mdc-form-field {
    width: 100%;
}

.pad-select {
    max-height: 356px !important;
}

.pad-select .mat-mdc-option {
    height: fit-content !important;
    line-height: unset !important;
    border-bottom: 1px solid #ddd;
    padding-top: 1rem;
    padding-bottom: 0.8rem;
}

.section-badge {
    border-radius: 0 0 .5rem .5rem;
    background-color: var(--light);
    border-top: 1px solid #ddd;
    padding: 1rem;
    margin-bottom: 1rem;
}

.my-sticky-top {
    position: sticky !important;
    top: 0;
    z-index: 10;
    display: block;
}

.my-sticky-top-1rem {
  position: sticky !important;
  top: 1rem;
  z-index: 10;
  display: block;
}

.fs-10 {
    font-size: 10pt;
}

.fs-11 {
    font-size: 11pt;
}

.fs-12 {
    font-size: 12pt;
}

.fs-13 {
    font-size: 13pt;
}

.fs-14 {
    font-size: 14pt;
}

.spacer {
    height: 10rem;
}

.bg-offwhite{
  background-color: var(--bg-offwhite);
}

.opacity-50 {
  opacity: 50%;
}

.servidor-tooltip .mdc-tooltip__surface{
  max-width: 800px;
  white-space: pre-line;
}

.white-mini-fab.mat-mdc-mini-fab.mat-mdc-mini-fab{
  background-color: white !important;
  color: black !important;
}
.white-mini-fab.mat-mdc-mini-fab.mat-mdc-mini-fab[disabled]{
  color: #00000055 !important;
}

.dialog{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.dialog .dialog-title{
  flex: 0 0 auto;
  padding: 1rem;
}
.dialog .dialog-content{
  flex: 0 0 (100% - 10rem);
  overflow-y: auto;
  max-height: 80vh;
  padding: 1rem;
}
.dialog .dialog-actions{
  margin-top: auto;
}
.quill-fix{
  padding-bottom: 10rem;
}
